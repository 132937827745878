<template>
    <div>
        <div v-if="tradings.length > 0">
            <b-card class="bg-blur">
                <b-table v-if="loading == false" class="mt-3" responsive :items="tradings" :fields="fields">
                    <template #cell(active)="row">
                        {{ row.item && row.item.active ? row.item.active.name : ''}}
                    </template>
                    <template #cell(side)="row">
                        {{ row.item.side }}
                    </template>
                    <template #cell(open_date)="row">
                        {{ format_date(row.item.open_date) }}
                    </template>
                    <template #cell(open_time)="row">
                        {{ row.item.open_time }}
                    </template>
                    <template #cell(open_price)="row">
                        {{ row.item.open_price }}
                    </template>
                    <template #cell(feeling)="row">
                        {{ row.item.feeling }}
                    </template>
                    <template #cell(strategy)="row">
                        {{ row.item.strategy ? row.item.strategy.name : 'No' }}
                    </template>
                    <template #cell(benefit)="row">
                        {{ row.item.benefit }}
                    </template>
                    <template #cell(risk)="row">
                        {{ row.item.risk }}
                    </template>
                    <template #cell(actions)="row">
                        {{ row.item.actions }}
                    </template>
                </b-table>
                <Loading type="table" v-else/>
            </b-card>
        </div>
        <Empty v-else/>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
    data () {
        return {
            loading: false,
            fields: [
                { key: 'active', label: 'Active' },
                { key: 'side', label: 'Side' },
                { key: 'open_date', label: 'Open Date' },
                { key: 'open_time', label: 'Open Time' },
                { key: 'open_price', label: 'Open Price' },
                { key: 'feeling', label: 'Feeling' },
                { key: 'strategy', label: 'Strategy' },
                { key: 'benefit', label: 'Net profit' },
                { key: 'risk', label: 'Risk' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('tradingPlan', ['getTradings']),
        getData () {
            this.loading = true
            this.getTradings().then(() => {
                this.loading = false
            })
        },
        format_date (value) {
            return moment(value).format('LL')
        }
    },
    computed: {
        ...mapState('tradingPlan', ['tradings'])
    }
}
</script>