<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-4 mt-2">
                <span>Active</span>
                <b-form-select v-model="form.active_id">
                    <b-form-select-option :value="category.id" v-for="category in actives" :key="category.id">{{ category.name }}</b-form-select-option>
                </b-form-select>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Broker</span>
                <b-form-select v-model="form.broker_id">
                    <b-form-select-option :value="category.id" v-for="category in brokers" :key="category.id">{{ category.name }}</b-form-select-option>
                </b-form-select>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Side</span>
                <b-form-select v-model="form.side">
                    <b-form-select-option value="Buy" >Buy</b-form-select-option>
                    <b-form-select-option value="Sell" >Sell</b-form-select-option>
                </b-form-select>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Open Date</span>
                <b-form-input type="date" v-model="form.open_date"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Open Time</span>
                <b-form-input type="time" v-model="form.open_time"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Open Price</span>
                <b-form-input v-model="form.open_price"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Close Date</span>
                <b-form-input type="date" v-model="form.close_date"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Close Time</span>
                <b-form-input type="time" v-model="form.close_time"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Close Price</span>
                <b-form-input v-model="form.close_price"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Stop Loss</span>
                <b-form-input v-model="form.stop_loss"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Gross profit</span>
                <b-form-input v-model="form.benefit"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Comissions</span>
                <b-form-input v-model="form.comission"/>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Strategy</span>
                <b-form-select v-model="form.strategy_id">
                    <b-form-select-option :value="category.id" v-for="category in strategies" :key="category.id">{{ category.name }}</b-form-select-option>
                </b-form-select>
            </div>
            <div class="col-12 col-sm-4 mt-2">
                <span>Feeling</span>
                <b-form-input v-model="form.feeling"/>
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Uploading signal" title="Create signal" @click="onSubmit"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            form: {
                active_id: null,
                broker_id: null,
                strategy_id: null,
                side: null,
                open_date: null,
                open_time: null,
                open_price: null,
                close_date: null,
                close_time: null,
                close_price: null,
                stop_loss: null,
                benefit: null,
                comission: null,
                feeling: null
            },
            loading: false
        }
    },
    created () {
        this.getActives()
        this.getBrokers()
        this.getStrategies()
    },
    methods: {
        ...mapActions('tradingPlan', ['getActives', 'storeTrading', 'getBrokers', 'getStrategies', 'getTradings']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
            this.storeTrading(this.form).then(() => {
                this.loading = false
                openNotification()
                this.getTradings()
                this.$emit('close')
            })
        }
    },
    computed: {
        ...mapState('tradingPlan', ['actives', 'brokers', 'strategies'])
    }
}
</script>