<template>
  <b-card class="h-100">
    <div id="bars-staking"></div>
  </b-card>
</template>

<script>
import ApexCharts from 'apexcharts'
export default {
  data () {
    return {}
  },
  mounted () {
    this.initTimelineChart()
  },
  methods: {
    initTimelineChart () {
      const options = {
        chart: {
          type: 'area',
          height: 300,
          foreColor: '#ffffff',
          fontFamily: 'Rubik, sans-serif',
          stacked: true,
          dropShadow: {
            enabled: false,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06
          },
          toolbar: { show: true }
        },
        colors: ['#FFBD00'],
        stroke: {
          curve: 'smooth',
          width: 0
        },
        dataLabels: { enabled: false },
        series: [
          {
            name: '',
            data: ['124', '142', '345', '646', '123']
          }
        ],
        markers: {
          size: 0,
          strokeColor: '#fff',
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: { size: 6 }
        },
        xaxis: {
          categories: ['1234', '012', '12', '435', '5456'],
          axisBorder: { show: true },
          axisTicks: { show: true }
        },
        yaxis: {
          show: true,
          labels: {
            offsetX: -10,
            offsetY: 0,
            formatter: function (val, index) {
              return '$' + val
            }
          },
          tooltip: { enabled: true }
        },
        grid: {
          show: true,
          padding: {
            left: -5,
            right: 5
          }
        },
        tooltip: {
          x: { format: 'dd MMM yyyy' }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0.4,
            stops: [0, 100, 100]
          }
        }
      }

      const chart = new ApexCharts(
        document.querySelector('#bars-staking'),
        options
      )

      chart.render()
    }
  }
}
</script>
