<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-3 mb-3">
                <Stats />
            </div>
            <div class="col-sm-12 col-md-8 col-lg-9 mb-3">
                <Bar />
            </div>
            <div class="col-12 mt-4">
                <div class="text-right mt-3">
                    <b-button variant="primary" @click="modalForm = true">
                        Upload trading
                    </b-button>
                </div>
                <Transactions class="mt-3" />
            </div>
        </div>
        <b-modal title="Crea un nuevo trade" centered size="xl" hide-footer v-model="modalForm">
            <Form @close="modalForm = false" />
        </b-modal>
    </div>
</template>
<script>
import Bar from './Bar.vue'
import Stats from './Stats.vue'
import Transactions from './Transactions.vue'
import Form from './Form'
import { mapActions } from 'vuex'
export default {
    components: {
        Bar,
        Stats,
        Transactions,
        Form
    },
    data () {
        return {
            modalForm: false,
            loading: false
        }
    }
}
</script>