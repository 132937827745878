<template>
    <b-card class="h-100">
        <b-card class="bg-secondary">
            <div class="row">
                <div class="col-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9998 21.0036C7.02721 21.0036 2.99609 16.9725 2.99609 11.9998C2.99609 7.02721 7.02721 2.99609 11.9998 2.99609C16.9725 2.99609 21.0036 7.02721 21.0036 11.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.50977 8.99923H20.4238" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.51074 15.0012H12.0003" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.0018 11.9998C16.0094 9.20492 15.2602 6.46008 13.8339 4.05652C13.4565 3.40049 12.7574 2.99609 12.0006 2.99609C11.2438 2.99609 10.5447 3.40049 10.1673 4.05652C7.27625 8.95782 7.27625 15.0428 10.1673 19.9441C10.5465 20.598 11.2443 21.0013 12.0001 21.0036" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7881 19.6324V16.8752C14.7896 16.5227 14.9807 16.1982 15.2883 16.0259L17.7783 14.6253C18.0749 14.4582 18.4372 14.4582 18.7337 14.6253L21.2238 16.0259C21.5317 16.1979 21.7229 16.5225 21.724 16.8752V19.6324C21.7227 19.985 21.5315 20.3096 21.2238 20.4817L18.7327 21.8823C18.4359 22.0484 18.0742 22.0484 17.7773 21.8823L15.2873 20.4817C14.9795 20.3098 14.7886 19.9849 14.7881 19.6324V19.6324Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.2531 18.2539V22.0045" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.9209 16.3828L18.2523 18.2536L21.5837 16.3828" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="col-7">
                Estimated Win Ratio
            </div>
            <div class="col-3 pt-4">
                100%
            </div>
            </div>
        </b-card>
        <b-card class="bg-secondary mt-3">
            <div class="row">
                <div class="col-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9998 21.0036C7.02721 21.0036 2.99609 16.9725 2.99609 11.9998C2.99609 7.02721 7.02721 2.99609 11.9998 2.99609C16.9725 2.99609 21.0036 7.02721 21.0036 11.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.50977 8.99923H20.4238" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.51074 15.0012H12.0003" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.0018 11.9998C16.0094 9.20492 15.2602 6.46008 13.8339 4.05652C13.4565 3.40049 12.7574 2.99609 12.0006 2.99609C11.2438 2.99609 10.5447 3.40049 10.1673 4.05652C7.27625 8.95782 7.27625 15.0428 10.1673 19.9441C10.5465 20.598 11.2443 21.0013 12.0001 21.0036" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7881 19.6324V16.8752C14.7896 16.5227 14.9807 16.1982 15.2883 16.0259L17.7783 14.6253C18.0749 14.4582 18.4372 14.4582 18.7337 14.6253L21.2238 16.0259C21.5317 16.1979 21.7229 16.5225 21.724 16.8752V19.6324C21.7227 19.985 21.5315 20.3096 21.2238 20.4817L18.7327 21.8823C18.4359 22.0484 18.0742 22.0484 17.7773 21.8823L15.2873 20.4817C14.9795 20.3098 14.7886 19.9849 14.7881 19.6324V19.6324Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.2531 18.2539V22.0045" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.9209 16.3828L18.2523 18.2536L21.5837 16.3828" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="col-7">
                Estimated risk/benefit ratio
            </div>
            <div class="col-3 pt-4">
                1:4
            </div>
            </div>
        </b-card>
        <b-card class="bg-secondary mt-3">
            <div class="row">
                <div class="col-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9998 21.0036C7.02721 21.0036 2.99609 16.9725 2.99609 11.9998C2.99609 7.02721 7.02721 2.99609 11.9998 2.99609C16.9725 2.99609 21.0036 7.02721 21.0036 11.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.50977 8.99923H20.4238" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.51074 15.0012H12.0003" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.0018 11.9998C16.0094 9.20492 15.2602 6.46008 13.8339 4.05652C13.4565 3.40049 12.7574 2.99609 12.0006 2.99609C11.2438 2.99609 10.5447 3.40049 10.1673 4.05652C7.27625 8.95782 7.27625 15.0428 10.1673 19.9441C10.5465 20.598 11.2443 21.0013 12.0001 21.0036" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7881 19.6324V16.8752C14.7896 16.5227 14.9807 16.1982 15.2883 16.0259L17.7783 14.6253C18.0749 14.4582 18.4372 14.4582 18.7337 14.6253L21.2238 16.0259C21.5317 16.1979 21.7229 16.5225 21.724 16.8752V19.6324C21.7227 19.985 21.5315 20.3096 21.2238 20.4817L18.7327 21.8823C18.4359 22.0484 18.0742 22.0484 17.7773 21.8823L15.2873 20.4817C14.9795 20.3098 14.7886 19.9849 14.7881 19.6324V19.6324Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.2531 18.2539V22.0045" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.9209 16.3828L18.2523 18.2536L21.5837 16.3828" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="col-7">
                Wiset ratio
            </div>
            <div class="col-3 pt-4">
                5
            </div>
            </div>
        </b-card>
        <b-button disabled class="mt-3 w-100" size="lg" variant="primary" block>
            View stats
        </b-button>
    </b-card>
</template>